/* Hide all slides by default */
.slide {
    display: none;
  }
  
  /* Show the slide with the "visible" class */
  .slide.visible {
    display: block;
  }
  
  /* Center align the controls */
  .carousel__controls {
    text-align: center;
  }
  
  /* Styles for the pagination dots */
  .pagination {
    text-align: center;
    margin-top: 20px; /* Adjust as needed */
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #333; /* Change to active color */
  }
  
  .carousel-image {
    max-width: 100%;
    aspect-ratio: 3/2;
    height: auto;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out; /* Move transition here */
    opacity: 1;
  }
  
  .carousel-image:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  
  