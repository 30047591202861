/* Global Styles */
body {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

h1, h2, h3 {
  margin: 0.5em 0;
  text-align: left;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-weight: bolder;
  font-style: normal;
  font-size: 15px; /* Default font size */
  letter-spacing: .06em;
  line-height: 1.6em;
  text-transform: none;
  color: rgba(0, 0, 0, .9);
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

a:hover {
  color: #000000;
  opacity: 0.5;
}

p {
  margin: 0.5em 0;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 10px; /* Default font size */
  letter-spacing: .06em;
  line-height: 1.6em;
  text-transform: none;
  color: rgba(0, 0, 0, .9);
}

#footer-link {
  margin: 0.5em 0;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 10px; /* Default font size */
  letter-spacing: .06em;
  line-height: 1.6em;
  text-transform: none;
  color: rgba(0, 0, 0, .9);
}

/* Header Styles */
header {
  z-index: 100;
  text-align: center;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

header h1 {
  margin-bottom: 1rem;
}

header h1 a {
  margin-top: 0;
  font-size: xx-large;
  text-decoration: none;
  color: inherit;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

header h1 a:hover {
  opacity: 0.25;
}

nav {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 15px;
}

/* Footer Styles */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #555;
  padding: 10px;
  text-align: left;
  font-size: small;
  width: 100%;
  margin-left: 1rem;
}

/* Main Container Styles */
.main-container {
  padding-top: 100vh;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Section Container Styles */
.section-container {
  margin: 3rem 0;
  height: 100%;
  max-width: 800px;
  display: block;
  align-items: center;
  justify-content: center;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  header h1 a {
    font-size: large;
  }

  a {
    font-size: 14px;
  }

  p {
    font-size: 8px;
  }

  h1, h2, h3 {
    font-size: 12px; /* Adjusted font size for mobile */
  }
}
